import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import Layout from '../components/Layout'

export const BlogPostTemplate = ({
  content,
  categories,
  tags,
  title,
  date,
  author,
}) => {
  const intl = useIntl()

  return (
    <section className="section-bubble1">
      <section className="section">
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light rainbow-underline">
                {title}
              </h1>
              <div
                className="rainbow-link"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <div style={{ marginTop: `4rem` }}>
                <p>
                  {`${date} - ${intl.messages.postedBy} `}
                  <Link to={`/author/${author.slug}`}>{author.name}</Link>
                </p>
                <div className="columns">
                  <div className="column">
                    {categories && categories.length ? (
                      <div>
                        <h4>{intl.messages.categories}</h4>
                        <ul className="taglist">
                          {categories.map(category => (
                            <li key={`${category.slug}cat`}>
                              <Link to={`/categories/${category.slug}/`}>
                                {category.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                  <div className="column">
                    {tags && tags.length ? (
                      <div>
                        <h4>{intl.messages.tags}</h4>
                        <ul className="taglist">
                          {tags.map(tag => (
                            <li key={`${tag.slug}tag`}>
                              <Link to={`/tags/${tag.slug}/`} className="tag">
                                {tag.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const { wpPost: post } = data

  return (
    <Layout>
      <Helmet title={`${post.title} | Klub sportowy Lemiesz Team`} />
      <BlogPostTemplate
        content={post.content}
        categories={post.categories.nodes}
        tags={post.tags.nodes}
        title={post.title}
        date={post.date}
        author={post.author.node}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on WpPost {
    id
    slug
    content
    date(formatString: "DD-MM-YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "DD-MM-YYYY")
      categories {
        nodes {
          name
          slug
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      author {
        node {
          name
          slug
        }
      }
    }
  }
`
